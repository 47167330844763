.fixedTop {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.4);
  box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.4);
}
.navigation {
  background-color: white;
  display: flex;
  font-size: 18px;
  height: 60px;
  align-items: center;

  .nav-active {
    padding: 17px 20px;
    background: #031323;
    color: white;
  }

  .navbar__logo {
    width: 100%;
    a {
      text-decoration: none;
      color: #031323;
    }
  }

  .logo {
    justify-content: start;
  }

  ul {
    margin: 0;
    display: inline-flex;
    align-items: center;
    width: 100%;
    @media screen and (min-width: 768px) {
      justify-content: end;
    }
    min-height: 40px;

    a {
      padding: 0 15px;
      text-decoration: none;
      color: #031323;
    }
  }

  .navbar__burger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    z-index: 3;
  }
}

@media screen and (max-width: 767px) {
  #banner {
    img {
      display: none;
    }
  }
  .navbar__logo {
    a {
      color: white !important;
      text-decoration: none;
    }
  }
  .navigation {
    background-color: #031323;
  }
  .show-nav .navbar__links {
    width: 100vw;
    right: 0;
    visibility: visible;
    background-color: #031323;
  }

  .navbar__links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: -100vw;
    bottom: 0;
    width: 0;
    height: 100vh;
    padding: 2rem;
    visibility: hidden;
    transition: all .8s ease-out;

    a {
      transform: translateY(100vh);

      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background: white;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  .navbar__link {
    display: block;
    padding: 1.5rem;
    font-size: 5vw;

    li {
      color: white;
      padding: 20px;
    }
  }
  .navbar__burger {
    display: block !important;
    position: fixed;
    top: 10px;
    right: 1rem;
  }
  .navbar__burger {
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
  }
  .burger-bar:before,
  .burger-bar:after,
  .burger-bar {
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: white;
    transition: all .5s ease-in-out;
  }
  .burger-bar:before,
  .burger-bar:after {
    content: "";
    position: absolute;
    left: 0;
  }
  .burger-bar:before {
    transform: translateY(-12px);
  }
  .burger-bar:after {
    transform: translateY(12px);
  }
  .show-nav {
    .navbar__links {
      z-index: 2;
      a {
        transform: translateY(0);
      }

      .slideInDown-1 {
        transition: all 1s ease-out;
      }
    }

    .burger-bar {
      width: 0;
      background: transparent;

      &:after {
        transform: rotate(-45deg)
      }

      &:before {
        transform: rotate(45deg)
      }
    }
  }
}