.notFound {
  background-color: #031323;
  height: 100vh;
  text-align: center;
  padding: 50px;
  color: white;
  h3 {
    color: white;
    text-decoration: none;
  }
}