.border-bloc {
  .row {
    background-color: white;
    li {
      margin: 10px 0;
    }
  }
}
@media only screen and (min-width: $md) {
  .border-right {
    border-right: 1px solid lightgrey;
  }
  .opentowork {
    left: -60px;
    top: 60px;
  }
}
@media only screen and (max-width: $sm) {
  .opentowork {
    left: -70px;
    top: 30px;
  }
}
#root {
  .text-center {
    position: relative;
  }
}

.opentowork {
  cursor: pointer;
  text-decoration: none;
  transform: rotate(-45deg);
  position: absolute;
  background: #2f7a18;
  color: white !important;
  text-align: center;
  padding: 5px 70px;
  font-weight: bold;
  letter-spacing: 3px;
}