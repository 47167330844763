@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/Questrial-Regular.ttf);
}

@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/Michroma-Regular.ttf);
}

@font-face {
  font-family: "font-3";
  src: url(../assets/fonts/BakbakOne-Regular.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", serif;
$font-3: "font-3", serif;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$color-1: #fafeff;
$color-2: #00c1ec;
$color-3: #e7f7fe;
$color-4: #217BBA;
$black: rgb(40, 40, 40);
$red: rgb(253, 87, 87);

.success_show {
  background-color: green;
  color: white;
  padding: 10px;
  text-align: center;
}

.error_show {
  background-color: darkred;
  color: white;
  padding: 10px;
  text-align: center;
}

.button_icon {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

#root {
  overflow-x: hidden;
}

@media screen and (min-width: 768px) {
  .hide {
    display: none;
  }
}

h1 {
  font-family: $font-3;
}

h4 {
  background: #031323;
  padding: 10px;
  text-decoration: none;
  color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;

  &::selection {
    color: $color-1;
    background: $color-2;
  }
}

body {
  font-family: $font-1;
  height: 100vh;
  background-color: $color-1;
  color: $black;

  @media screen and (max-width: 430px) {
    position: relative;
    max-width: 100vw;
    overflow-y: initial;
    .btn-primary {
      width: 100% !important;
    }
  }
}

.credits {
  p {
    margin-top: 1rem;
  }
}

#swal2-html-container input.form-control {
  display: block;
  margin: 0;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
}